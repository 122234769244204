// Eamil
export const EMAIL_VALIDATION_ERROR_TEXT = "아이디는 이메일 형식으로 영문, 숫자, 특수문자를 이용하여, 최소 10자 ~ 최대 33자까지 입력하실 수 있습니다.";
export const EMAIL_DUPLICATE_TEXT = "이미 사용중인 아이디입니다.";
export const EMAIL_PLACEHOLDER = "이메일을 입력해주세요."
export const ID_PLACEHOLDER = "아이디를 입력해주세요."

// Password
export const PASSWORD_VALIDATION_ERROR_TEXT = "비밀번호는 영문, 숫자, 특수문자만 사용 가능하며, 2가지 이상을 조합하여 최소 10자 ~ 최대 64자까지 입력하실 수 있습니다.";
export const PASSWORD_NO_MATCH = "비밀번호가 일치하지 않습니다.";
export const PASSWORD_PLACEHOLDER = "비밀번호를 입력해주세요."
export const PASSWORD_RE_PLACEHOLDER = "비밀번호를 다시 입력해주세요."

// Name
export const STUDENT_NAME_VALIDATION_ERROR_TEXT = "이름은 한글, 영문, 숫자를 이용하여, 최소 2자 ~ 최대 10자까지 입력 가능합니다.";
export const PARENT_NAME_VALIDATION_ERROR_TEXT = "이름은 한글, 영문, 숫자를 이용하여, 최소 2자 ~ 최대 13자까지 입력하실 수 있습니다.";
export const NAME_FIRST_LETTER_VALIDATION_ERROR_TEXT = "첫 글자로 숫자, 특수문자를 사용하실 수 없습니다."
export const NAME_DUPLICATE_TEXT = "현재 동일한 이름을 사용 중입니다.";
export const NAME_PLACEHOLDER = "이름을 입력해주세요."

// Phone
export const PHONE_VALIDATION_ERROR_TEXT = "휴대폰 번호를 확인해주세요.";
export const PHONE_DUPLICATE_TEXT = "등록할 수 없는 휴대폰 번호입니다.";
export const PHONE_PLACEHOLDER = "휴대폰 번호를 입력해주세요."

// Validation Code
export const VALIDATION_CODE_PLACEHOLDER = "인증번호를 입력해주세요.";

// date-picker
export const DATEPICKER_PLACEHOLDER = "희망 이용 시간을 선택해주세요."

// time-picker
export const TIMEPICKER_PLACEHOLDER = "희망 이용 시간을 선택해주세요."
