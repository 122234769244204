import { useQuery } from '@tanstack/react-query'
// hooks
import useErrorModal from 'hooks/useErrorModal'
import customAxios from 'axios/customAxios';


const getMyInfoFetcher = () => {
  return customAxios({
    method: 'get',
    path: '/b2c/v2/user/info',
    auth: true
  }).then(data => data)
}

export const useMyInfoQuery = () => {
  const { unexpectedError } = useErrorModal();
  const QUERY_KEY = ['get/myInfo']

  return useQuery(
      QUERY_KEY,
      () => getMyInfoFetcher(),
      {
        staleTime: 1000 * 60 * 60 * 24,
        refetchOnWindowFocus: false,
        onError: () => { unexpectedError() } 
      }
    );
}
