import { format, lastDayOfMonth } from "date-fns";
import add from 'date-fns/add'

/*
 * hot to use
 * format: "yyyy.MM.dd HH:mm:ss"
 */

/**
 * 원하는 날짜의 format 형태로 변환
 * @param {date} date 
 * @param {string} formatString 
 * @returns 
 */
export const convertFormat = (date, formatString) => {
    if(formatString)
        return format(date, formatString);
    else
        return date;
}

/**
 * today date
 * @param {string} formatString 
 * @returns 
 */
export const getToday = (formatString) => {
    const date = new Date()
    return convertFormat(date, formatString)
}

/**
 * 이번 달 마지막 일자의 dat
 * @param {string} formatString 
 * @returns date
 */
export const getCurrentMonthLastDay = (formatString) => {
    const date = new Date()
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return convertFormat(lastDay, formatString)
}

/**
 * 특정 날짜에 minusNum 만큼 Day를 빼는 함수
 * @param {date} specificDate new Date()
 * @param {number} minusNum 1
 * @param {string} formatString n/a
 * @returns date
 */
export const minusDay = (specificDate, minusNum = 1, formatString) => {
    const date = new Date(specificDate);
    date.setDate(date.getDate() - minusNum);
    return convertFormat(date, formatString)
}

/**
 * 특정 날짜에 plusNum 만큼 Day를 더하는 함수
 * @param {date} specificDate new Date()
 * @param {number} plusNum 1
 * @param {string} formatString n/a
 * @returns date
 */
export const plusDay = (specificDate, plusNum = 1, formatString) => {
    const date = new Date(specificDate);
    date.setDate(date.getDate() + plusNum);
    return convertFormat(date, formatString)
}

/**
 * 특정 날짜에 minusNum 만큼 minute을 빼는 함수
 * @param {date} specificDate new Date()
 * @param {number} minusNum 1
 * @param {string} formatString n/a
 * @returns date
 */
export const minusMinute = (specificDate, minusNum = 60, formatString) => {
    const date = new Date(specificDate);
    date.setTime(date.getTime() - minusNum * 60 * 1000);
    return convertFormat(date, formatString)
}

/**
 * 특정 날짜에 plusNum 만큼 minute을 더하는 함수
 * @param {date} specificDate new Date()
 * @param {number} plusNum 1
 * @param {string} formatString n/a
 * @returns date
 */
export const plusMinute = (specificDate, plusNum = 1, formatString) => {
    const date = new Date(specificDate);
    date.setTime(date.getTime() + plusNum * 60 * 1000);
    return convertFormat(date, formatString)
}

/**
 * 특정 날짜에 plusNum 만큼 second을 더하는 함수
 * @param {date} specificDate new Date()
 * @param {number} plusNum 1
 * @param {string} formatString n/a
 * @returns date
 */
 export const plusSecond = (specificDate, plusNum = 1, formatString) => {
    const date = new Date(specificDate);
    date.setTime(date.getTime() + plusNum * 1000);
    return convertFormat(date, formatString)
}

export const isToday = (someDate) => {
    const today = new Date()
    return someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
}

export const getXm = () => {
    return format(new Date(), "a")
}

export const replaceXmToKorean = (stringDate) => {
    return stringDate.replace("AM", "오전").replace("PM", "오후")
}

/**
 * 
 * @param {string} date 
 * @param {number} len 1
 * @returns 
 */
export const replaceDayOfWeek = (dateString) => {
    dateString = dateString.replace(/Monday|Mon|Mo/g, '월');
    dateString = dateString.replace(/Tuesday|Tue|Tu/g, '화');
    dateString = dateString.replace(/Wednesday|Wed|We/g, '수');
    dateString = dateString.replace(/Thursday|Thu|Th/g, '목');
    dateString = dateString.replace(/Friday|Fri|Fr/g, '금');
    dateString = dateString.replace(/Saturday|Sat|Sa/g, '토');
    dateString = dateString.replace(/Sunday|Sun|Su/g, '일');
    return dateString;
}

/**
 * 17:30 같은 string time을 오후 5:30 와 같은 형식으로 변환해 주는 함수
 * @param {string} time 17:30:00 or 17:30
 * @returns xm(kr) hh:mm ex) 오후 05:30
 */
export const timeToXmTime = (time) => {
    return convertFormat(new Date("2000","01","01",...time.split(':')), "a hh:mm").replace("AM", "오전").replace("PM", "오후")
}

/**
 * 해당한 Month의 마지막 날을 반환하는 함수
 * @param {Date} date 
 * @returns Date
 */
export const getLastDayOfMonth = (date = new Date()) => {
    return lastDayOfMonth(date)
}

/**
 * 다음 달의 Date를 반환하는 함수
 * @param {Date} date 
 * @returns Date
 */
export const getNextMonthDate = (date = new Date()) => {
    return add(date, { months: 1 });
}

/**
 * 다음 달 마지막 일자 반환
 * @param {Date} date 
 * @returns Date
 */
export const getLastDayOfNextMonth = (date = new Date()) => {
    return lastDayOfMonth(add(date, { months: 1 }))
}
