import { lazy } from 'react'

export const retryLazy = (componentImport) =>
    lazy(async () => {
        try {
            const component = await componentImport()
            window.sessionStorage.setItem('pageRefreshed', 'false')
            return component
        } catch (error) {
            if (!(JSON.parse(window.sessionStorage.getItem('pageRefreshed') || 'false'))) {
                window.sessionStorage.setItem('pageRefreshed', 'true')
                return window.location.reload()
            }
            throw error
        }
    })
