import React, { useState, useEffect } from 'react';
import styled, { css } from "styled-components";
import { Link, useNavigate } from 'react-router-dom';
// Commons
import { getAccessToken, setAccessToken } from 'commons/Token/tokenStorage'
// images
import BusImage from 'assets/images/drawer/drawer_bus.png'
import BusSignImage from 'assets/images/drawer/drawer_sign.png'
import SplashLogo from "assets/images/logo/splash@4x.png"
import LogoImage from 'assets/images/logo/loginLogo.png'
// commons
// Date
import { convertFormat } from 'commons/Date'
import { emailRegExReplace, emailRegExTest } from 'commons/regex/Email'
import { passwordRegExReplace, passwordRegExTest } from 'commons/regex/Password'
import { ID_PLACEHOLDER, PASSWORD_PLACEHOLDER } from 'commons/AlertText'
// component
// Commons
import Modal from 'components/commons/Modal';
// Atoms
import InputEmail from 'components/atoms/Input/email'
import InputPassword from 'components/atoms/Input/password'
import Span from 'components/atoms/Span'
import Button from 'components/atoms/Button'
import Label from 'components/atoms/Label'
import CheckBox from 'components/atoms/CheckBox'
import Img from 'components/atoms/Image'
// Templates
import { OuterContainer } from 'components/templates/common/mainContainer';
// hooks
import useErrorModal from 'hooks/useErrorModal'
// axios
import customAxios from 'axios/customAxios'
// const
const SPLASH_DELAY_TIME_MS = 2000;


const LoginPage = () => {
    const navigate = useNavigate();

    const [isSplash, setIsSplash] = useState(sessionStorage.getItem("splashRenderStatus") ? "true" : "false");

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [autoLoginChecked, setAutoLoginChecked] = useState(false);
    const [isMisMatchModal, setIsMisMatchModal] = useState(false);

    const { expectedError, unexpectedError } = useErrorModal();

    // 주소 줄 때문에 추가된 js html, body, #root의 height를 조정한다
    useEffect(() => {
        document.body.style.height = "calc(var(--vh, 1vh) * 100)";

        function setScreenSize() {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
        setScreenSize();
        window.addEventListener('resize', setScreenSize);

        return () => {
            document.body.style.height = "100%"
            window.removeEventListener('resize', setScreenSize);
        }
    }, [])
    
    useEffect(() => {
        if (getAccessToken()){
            initPageBranch();
            return;
        }

        const timerSplash = setTimeout(function () {
            setIsSplash("true")
        }, SPLASH_DELAY_TIME_MS);
        
        return () => clearInterval(timerSplash)
    }, [])

    useEffect(() => {
        if(!sessionStorage.getItem("splashRenderStatus")){
            sessionStorage.setItem("splashRenderStatus", "1");
        }
    }, [])

    useEffect(() => {
        if(isMisMatchModal)
            window.gtag?.("event", "아이디_비밀번호_틀림");
    }, [isMisMatchModal])

    const onChangeEmail = value => setEmail(emailRegExReplace(value))
    const onChangePassword = value => setPassword(passwordRegExReplace(value))

    const initPageBranch = () => {
        customAxios({
            method: 'get',
            path: '/b2c/v2/ticket/check',
            auth: true,
            params: {
                startDate: convertFormat(new Date(), "yyyy-MM-dd"),
                endDate: convertFormat(new Date(2100, 1, 1), "yyyy-MM-dd")
            }
        }).then(data => {
            if(data.status === -1){
                expectedError(data)
                return;
            }

            if(data.data)
                navigate('/reservation-management')
            else{
                customAxios({
                    method: 'get',
                    path: '/b2c/v2/student',
                    auth: true
                }).then(data => {
                    navigate(data.data.length < 1 ? "/info/student" : "/reservation/type");
                })
            }
        }).catch(() => { unexpectedError() })
    }

    const onClickNext = () => {
        if(emailRegExTest(email) && passwordRegExTest(password)){
            customAxios({
                method: 'post',
                hostType: "auth",
                path: "/v2/login",
                params: { email, loginType: 'B2C', password }
            }).then(async data => {
                if (data.status === 1) {
                    const accessToken = data.data.accessToken;
                    setAccessToken(accessToken)

                    if(!autoLoginChecked){
                        customAxios({
                            method: "delete",
                            hostType: "auth",
                            path: "/v2/refresh/cookie"
                        })
                    }

                    const pathAfterLogin = await getPathAfterLogin()
                    navigate(pathAfterLogin)

                    // initPageBranch()

                    if(!autoLoginChecked)
                        sessionStorage.setItem("sessionLogin", "1");
                    
                }
                else if (data.code === "S500" || data.code === "L005") setIsMisMatchModal(true);
                else expectedError(data);
            })
        }
        // Email과 Password가 정해진 규칙에 맞지 않을 때
        else setIsMisMatchModal(true);
    }


    return (
        <>
            <Modal open={isMisMatchModal} onClose={() => setIsMisMatchModal(false)} onClickOk={() => setIsMisMatchModal(false)}>
                <p>아이디 또는 비밀번호를</p>
                <p>확인해주세요.</p>
            </Modal>

            <OuterContainer padding={"0"} height={"100%"} backgroundColor={"#FFFCED"}>
                <SplashContainer active={isSplash}>
                    <LogoContainer active={isSplash}>
                        <LogoWrap active={isSplash}>
                            <LogoImageDiv active={isSplash} />
                            {/* <Img src={isSplash === "true" ? LogoImage : SplashLogo} width={isSplash === "true" ? "50px" : "220px"} alt="Yellow Bus Splash Logo" /> */}
                            <SplashParagraph active={isSplash}>기존 학원버스 이용자는</SplashParagraph>
                            <SplashParagraph active={isSplash}>앱스토어, 구글플레이에서</SplashParagraph>
                            <SplashParagraph active={isSplash}><Span bold textOrangeColor>옐로우버스</Span>를 검색해주세요.</SplashParagraph>
                        </LogoWrap>
                    </LogoContainer>

                    <BusContainer>
                        {/* <Img src={BusImage} width={"54px"} style={busStyle} as={animated.img} alt="" /> */}
                        <BusImg src={BusImage} width={"54px"} alt="splash move bus icon" />
                        <Img src={BusSignImage} width={"16px"} alt="bus sign icon" />
                    </BusContainer>

                    <LoginContainer active={isSplash}>
                        <ContentsContainer>
                            <div>
                                <InputWrap>
                                    <Label lh22 textGrayColor>아이디</Label>
                                    <InputEmail
                                        value={email}
                                        onChange={(e) => onChangeEmail(e.target.value)} 
                                        onEnter={() => (email && password) && onClickNext()}
                                        onClear={() => setEmail("")} 
                                        placeholder={ID_PLACEHOLDER} 
                                    />

                                    <Label lh22 textGrayColor>비밀번호</Label>
                                    <InputPassword
                                        value={password} 
                                        onChange={(e) => onChangePassword(e.target.value)} 
                                        onEnter={() => (email && password) && onClickNext()}
                                        onClear={() => setPassword("")} 
                                        placeholder={PASSWORD_PLACEHOLDER}
                                    />
                                </InputWrap>

                                <OptionContainer>
                                    <AutoLoginWrap>
                                        <CheckBox
                                            id="checkbox_autoLogin"
                                            checked={autoLoginChecked}
                                            onChange={() => {
                                                setAutoLoginChecked(prev => !prev)
                                            }}
                                        />
                                        <label htmlFor="checkbox_autoLogin">자동 로그인</label>
                                    </AutoLoginWrap>

                                    <FindWrap>
                                        <span onClick={() => { navigate('/find/email/write-info'); }}>아이디 찾기</span>
                                        <VerticalLine />
                                        <span onClick={() => { navigate('/find/pwd/write-info'); }}>비밀번호 찾기</span>
                                    </FindWrap>
                                </OptionContainer>

                                <Button id="test_login_button" className={(email && password) && "active"} width="100%" onClick={() => { (email && password) && onClickNext() }}>로그인</Button>
                            </div>
                        </ContentsContainer>

                        <SignUpWrap>
                            <p>계정이 없으신가요?</p>
                            <Link to={"/sign-up/terms-of-service"} id="id_sign_up">회원가입 하기</Link>
                        </SignUpWrap>
                    </LoginContainer>
                </SplashContainer>

            </OuterContainer>
        </>
    )
}
export default LoginPage;


export const getPathAfterLogin = () => {

    return new Promise(resolve => {
        customAxios({
            method: 'get',
            path: '/b2c/v2/ticket/check',
            auth: true,
            params: {
                startDate: convertFormat(new Date(), "yyyy-MM-dd"),
                endDate: convertFormat(new Date(2100, 1, 1), "yyyy-MM-dd")
            }
        }).then(data => {
            if(data.data)
                resolve("/reservation-management")
            else{
                customAxios({
                    method: 'get',
                    path: '/b2c/v2/student',
                    auth: true
                }).then(async data => {
                    if(data.data.length < 1){
                        resolve("/info/student")
                    }
                    else{
                        resolve("/reservation/type")
                    }
                })
            }
        })
    });
}


const SplashContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    height: 100vh;

    ${props => props.active === "true" && css`
        height: auto;
        transition: all ease 1.3s 0s;
    `}
`

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;

    width: 100%;
    height: 100%;

    background-color: #FFF;

    ${props => props.active === "true" && css`
        /* height: 250px; */
        /* transition: all ease 1s 0s; */
    `}

    /* @keyframes splashEndDecreaseHeight {
        0% { height: 100%; }
        100% { height: 250px; }
    }
    animation: splashEndDecreaseHeight 1s 2s ease-out forwards; */

`
const LogoWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 40px;
    margin-bottom: 75px;
    
    @keyframes splashStartLogoUp {
        0% { margin-top: 20vh; }
        100% { margin-top: 40px; }
    }
    ${props => props.active === "false" && css`
        animation: splashStartLogoUp 2s 0s ease alternate forwards;
    `}
`
const LogoImageDiv = styled.div`
    flex-shrink: 0;
    width: 220px;
    height: 130px;
    
    background-image: url(${SplashLogo});
    background-repeat: no-repeat;
    background-size: 220px;
    background-position: left 50% bottom 50%;

    margin-bottom: 40px;

    ${props => props.active === "true" && css`
        width: 50px;
        height: 50px;

        margin-bottom: 20px;

        background-image: url(${LogoImage});
        background-size: contain;

        transition: all ease 0.6s 0s;
    `}
`
const SplashParagraph = styled.span`
    color: #585858;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
`

const BusContainer = styled.div`
    flex-shrink: 0;
    position: relative;

    width: 100%;
    height: 18px;

    background-color: #FFED99;
    border: none;

    > img:first-of-type{
        position: absolute;
        top: -28px;
    }
    > img:last-of-type{
        position: absolute;
        top: -28px;
        right: 16px;
    }
`

const LoginContainer = styled.div`
    position: relative;
    width: 100%;
    height: 0;

    background-color: #FFFCED;
    overflow: hidden;
    padding: 0 16px;

    @keyframes viewLoginArea {
        20% {
            padding-top: 40px;
            padding-bottom: 70px;
        }
        100% { 
            padding-top: 40px;
            padding-bottom: 70px;
            height: 440px;
        }
    }
    ${props => props.active === "true" && css`
        animation: viewLoginArea 0s 0s ease-out forwards;
    `}
    ${props => props.active === "false" && css`
        animation: viewLoginArea 1s 2s ease-out forwards;
    `}
;
`

// 중단 로그인 Input, Button 영역
const ContentsContainer = styled.div`
    // border: 1px solid blue;
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: auto;
    /* min-height: 420px; */

    // 브라우저 크기가 변해도 중앙에 Tag들을 모아두기 위한 div
    > div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* padding-top: 26px; */
    }
`

const InputWrap = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    /* margin: 25px 0 24px 0; */

    > label {
        display: flex;
        align-items: center;
        
        height: 22px;
        margin-bottom: 4px;

        font-weight: 700;
        line-height: 22px;
    }

    // 비밀번호 Text
    > label:last-of-type {
        margin-top: 12px;
    }
`

const OptionContainer = styled.div`
    // border: 1px solid blue;
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* @media screen and (max-width: 350px) {
        width: 100%;
        flex-direction: column;
        align-items: center;
    } */
    height: auto;
    padding: 16px 0 24px 0;
`

const AutoLoginWrap = styled.div`
    // border: 1px solid blue;
    width: 89px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    // Checkbox + 자동로그인
    > label {
        cursor: pointer;
    }
`

const FindWrap = styled.div`
    // border: 1px solid blue;
    /* @media screen and (max-width: 350px) {
        margin-top: 20px;
    } */
    display: flex;
    align-items: center;

    // 이메일 찾기 | 비밀번호 재설정 Text Span
    > span{
        color: #585858;
        cursor: pointer;
    }
`

const VerticalLine = styled.div`
    border-left : 1px solid #585858;
    height : 14px;
    margin: 0 8px;
`

// 하단 회원가입 글자 영역
const SignUpWrap = styled.div`
    // border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    margin-top: 24px;
    /* height: 120px; */
    /* min-height: 50px; */
    
    font-size: 16px;
    font-weight: 400;
    color: #585858;
    
    > a {
        margin-left: 8px;
        font-weight: 700;
        text-decoration: underline;
    }
`

const BusImg = styled(Img)`
    @keyframes moveBus {
        0% { right: 800px; }
        100% { right: 38px; }
    }
    animation: moveBus 1.3s 0s ease forwards;
`