import { useQuery } from '@tanstack/react-query'
// hooks
import useErrorModal from 'hooks/useErrorModal'
import customAxios from 'axios/customAxios';

// 이용 약관
const getTermsOfPolicyFetcher = () => {
  return customAxios({
    method: 'get',
    hostType: "operation",
    path: '/policy/viewer',
    params: {
      type: "TERMS",
      userType: "SHARE_BUS"
    }
  })
  .then(data => data);
}

export const useGetTermsOfPolicyQuery = () => {
  const { unexpectedError } = useErrorModal();
  const QUERY_KEY = ['get/termsOfPolicy']

  return useQuery(
      QUERY_KEY,
      () => getTermsOfPolicyFetcher(),
      { 
        staleTime: 1000 * 60 * 5,
        refetchOnWindowFocus: false,
        onError: () => { unexpectedError() } }
    );
}

// 개인정보 처리방침
const getPrivacyOfPolicyFetcher = () => {
  return customAxios({
    method: 'get',
    hostType: "operation",
    path: '/policy/viewer',
    params: {
      type: "PRIVACY",
      userType: "SHARE_BUS"
    }
  })
  .then(data => data);
}

export const useGetPrivacyOfPolicyQuery = () => {
  const { unexpectedError } = useErrorModal();
  const QUERY_KEY = ['get/privacyOfPolicy']

  return useQuery(
      QUERY_KEY,
      () => getPrivacyOfPolicyFetcher(),
      { 
        staleTime: 1000 * 60 * 5,
        refetchOnWindowFocus: false,
        onError: () => { unexpectedError() } }
    );
}
