import create from 'zustand';

export const useStore = create(set => ({
    isOpen: false,
    openDrawer: () => set({ isOpen: true }),
    closeDrawer() {
        set({ isOpen: false })
    }
    // closeDrawer: () => set((state) => ({ isOpen: state.isOpen }))
    // closeDrawer: () => set((state) => ({ isOpen: state.isOpen }))
}));