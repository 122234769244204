import create from 'zustand';

const initState = {
    isModal: false,
    data: {
        "status": 0,
        "code": "999",
        "message": "정의되지 않은 오류"
    }
}

// export const useStore = create(set => ({
//     ...initState,

//     setIsModal: (bool) => set({ isModal: bool }),
//     setData: (data) => set((state) => ({ data: { ...state.data, ...data } })),
// }));

export const useStore = create(set => ({
    ...initState,

    setIsModal: (bool) => set({ isModal: bool }),
    setData: (data) => set((state) => ({ data: { ...state.data, message: "정의되지 않은 오류", ...data } })),
}));