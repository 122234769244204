import { PASSWORD_MAX_LENGTH } from 'commons/Settings'

/**
 * 비밀번호 정규식 테스트
 * @param {string} password 
 * @returns Boolean
 */
 export const passwordRegExTest = (password) => {
    return /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()\-_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_\-+=]{10,64}$/.test(password);
}

/**
 * 비밀번호 정규식 Replace 함수
 * \uAC00-\uD7A3 : 가-힣 (음계)
 * \u3131-\u314E : ㄱ-ㅎ (자음)
 * \u314F-\u3163 : ㅏ-l (모음)
 * \u318D\u119E\u11A2\u2022\u2025a\u00B7\uFE55 : 천지인 키보드 문자
 * 
 * @param {string} password
 * @returns Boolean
 */
 export const passwordRegExReplace = (password) => {
    return password.replace(/[^A-Za-z\d~!@#$%^&*()_\-+=]/g, "").slice(0, PASSWORD_MAX_LENGTH);
    // return password.slice(0, PASSWORD_MAX_LENGTH);
}

export const passwordRegExWordTest = (word) => {
    return /[A-Za-z\d~!@#$%^&*()_\-+=]/.test(word);
}
