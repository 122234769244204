import { useEffect } from "react";

const analyticsDeploymentEnvironment = "release";


const BeusableAnalytics = () => {
    
    useEffect(() => {
        if (process.env.REACT_APP_ENV_TYPE === analyticsDeploymentEnvironment) {
            (function(w, d, a){
                w.__beusablerumclient__ = {
                    load : function(src){
                        var b = d.createElement("script");
                        b.src = src; b.async=true; b.type = "text/javascript";
                        d.getElementsByTagName("head")[0].appendChild(b);
                    }
                };w.__beusablerumclient__.load(a + "?url=" + encodeURIComponent(d.URL));
            })(window, document, "//rum.beusable.net/load/b230316e094012u101");
        }
    }, []);

    return <></>;
}
export default BeusableAnalytics; 
