import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import styled from "styled-components";
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import parse from 'html-react-parser'
// images
import CloseIcon from 'assets/images/signUp/close@4x.png'
// queries
import { useGetPrivacyOfPolicyQuery } from 'queries/Policy/usePolicyQuery';
// components
// atoms
import { H2 } from 'components/atoms/Header';
import Img from 'components/atoms/Image';


const PrivacyOfPolicy = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        setOpen: () => { setOpen(true) },
        setClose: () => { setOpen(false) },
    }));

    const contentRef = useRef();

    const [isOpen, setOpen] = useState(false);
    const [isViewPrevTerms, setIsViewPrevTerms] = useState(false)

    const { data } = useGetPrivacyOfPolicyQuery();

    const {
        content,
        // expectedAt,
        // id,
        previous,
        // type
    } = data?.data ?? {};

    useEffect(() => {
        if (isOpen) {
            contentRef.current.scrollTo(0, 0);
            setIsViewPrevTerms(false)
        }
    }, [isOpen])

    const onClickSwitchPrevTerms = () => {
        contentRef.current.scrollTo(0, 0);
        setIsViewPrevTerms(true)
    }


    return (
        <SwipeableBottomSheet
            open={isOpen}
            fullScreen={true}
            style={{
                zIndex: '1300',
                boxShadow: 'none'
            }}
            swipeableViewsProps={{ disabled: true }}
        >
            <TitleBar>
                개인정보 처리방침
                <CloseButton onClick={() => setOpen(false)}>
                    <Img src={CloseIcon} pointer width={30} alt="Close Button" />
                </CloseButton>
            </TitleBar>
            <ContentContainer>
                <H2>[필수] 개인정보 처리 방침</H2>

                <ContentScroll ref={contentRef}>
                    <ContentWrap>
                        {(isViewPrevTerms === false && content) && parse(content ?? "")}
                        {(isViewPrevTerms === true && previous) && parse(previous?.content ?? "")}

                        {!data && "약관이 존재하지 않습니다."}
                    </ContentWrap>
                    {
                        (isViewPrevTerms === false && previous) &&
                        <PrevTermsButton onClick={onClickSwitchPrevTerms}>이전 개인정보 처리방침 보기</PrevTermsButton>
                    }
                </ContentScroll>

            </ContentContainer>
        </SwipeableBottomSheet>
    )
});
export default PrivacyOfPolicy;


const TitleBar = styled.div`

    position: fixed;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 48px;
    min-height: 48px;

    font-weight: 700;
    font-size: 16px;
    background: #FFFFFF;
    box-shadow: inset 0px -0.5px 0px #CECECE;
`
const CloseButton = styled.div`
    position: absolute;
    right: 16px;

    width: 30px;
    height: 30px;
`
const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;

    /* margin-top: 48px; */
    padding: 72px 16px 0 16px;

    /* overflow-y: auto; */

    & > :first-child{
        margin-bottom: 20px;
    }
`
const ContentScroll = styled.div`
    height: 100%;
    overflow-y: auto;
`
const ContentWrap = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    color: #666666;

    img{
        width: 100%;
    }
`
const PrevTermsButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    min-width: 70px;
    height: 56px;
    margin: 20px 0;
    padding: 20px 16px;

    border: 1px solid #FFCD00;
    border-radius: 12px;
    line-height: 28px;
    font-size: 14px;
    font-weight: 700;
    color: #000;
    cursor: pointer;
`
