import axios from 'axios';
// config
import { ADDRESS_API_DEFAULT, ADDRESS_API_OPERATION, ADDRESS_API_AUTH } from 'axios/config.js'


axios.defaults.withCredentials = true;
// axios.defaults.headers.common["accept"] = "*/*";
// axios.defaults.headers.common["ContentType"] = "application/json";


/**
 * custom axios
 * object
 * @param {string} method get, post, put, patch, delete
 * @param {object} header { accept: '*\/*', ContentType: 'application/json' }
 * @param {bool} auth add header token
 * @param {string} hostType: default, auth, operation
 * @param {string} path: path
 * @param {string} customURL: full protocol+host+path
 * 
 * @returns axios data
 */
const customAxios = async ({
  method="get",
  hostType="default",
  path="",
  headers={ accept: '*/*', ContentType: 'application/json' },
  auth=false,
  params,
  customURL="",
}) => {

  const url = customURL || connectionURL(hostType, path);

  if(auth){
    headers = { ...headers, Authorization: true }
  }

  // axios method
  if(method === 'get'){
    return await axios.get(url, { params, headers })
      .then(({ data }) => data)
      .catch(e => e)
    }
  else if(method === 'post'){
    return await axios.post(url, params, { headers })
    .then(({ data }) => data)
    .catch(e => e)
  }
  else if(method === 'put'){
    return await axios.put(url, params, { headers })
    .then(({ data }) => data)
    .catch(e => e)
  }
  else if(method === 'patch'){
    return await axios.patch(url, params, { headers })
    .then(({ data }) => data)
    .catch(e => e)
  }
  else if(method === 'delete'){
    return await axios.delete(url, { headers, data: params })
    .then(({ data }) => data)
    .catch(e => e)
  }
  else { return false }
}
export default customAxios;


export const connectionURL = (hostType = "default", path = "") => {
  if(hostType === "default")
    return `${ADDRESS_API_DEFAULT}${path}`
  else if(hostType === "auth")
    return `${ADDRESS_API_AUTH}${path}`
  else if(hostType === "operation")
    return `${ADDRESS_API_OPERATION}${path}`
  else
    return `${ADDRESS_API_DEFAULT}${path}`
}
