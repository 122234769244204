/**
 * 
 * @returns accessToken
 */
export const getAccessToken = () => {
  return sessionStorage.getItem("at");
}

/**
 * 
 * @returns refreshToken
 */

export const setAccessToken = (token) => {
  sessionStorage.setItem("at", token);
}

export const clearToken = () => {
  sessionStorage.clear();
}
