import { createContext, useState } from "react";
import Loading from "components/commons/Loading";

export const SpinnerContext = createContext({});

export const SpinnerProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(false);
  return (
    <SpinnerContext.Provider value={{ isLoading, setLoading }}>
      {children}
      <Loading loading={isLoading} />
    </SpinnerContext.Provider>
  )
}
