import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SpinnerProvider } from "context/SpinnerContext";
import App from './App';

// CSS import
import 'antd/dist/antd.min.css';
import 'react-calendar/dist/Calendar.css';

import "assets/styles/defaultStyle.scss"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // ✅ globally default to 20 seconds
      staleTime: 1000 * 20,
    },
  },
})

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Suspense fallback={<label></label>}>
      <BrowserRouter>
        <SpinnerProvider>
          <App />
        </SpinnerProvider>
      </BrowserRouter >
    </Suspense>
  </QueryClientProvider>
  ,
  document.getElementById("root")
);