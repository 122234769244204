// commons
import { useStore as useErrorModalStore } from 'stores/errorModalStore'


const useModal = () => {
  const { setIsModal, setData } = useErrorModalStore();

  const expectedError = (data) => {
    window.gtag?.("event", "500", {
      code: data.code ?? "",
      message: data.message ?? ""
    });
    setData(data)
    setIsModal(true)
  }

  const unexpectedError = () => {
    window.gtag?.("event", "400", {
      path: window.location.href
    });
    setData({ 
      "code": "999", 
      "message": "알 수 없는 오류" })
    setIsModal(true)
  }

  return {
    expectedError,
    unexpectedError
  }
}
export default useModal;
