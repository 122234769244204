import styled from "styled-components";


/**
 * fontSize(24px), fs25, fs26, fs27, fs28
 * 
 * fontWeight(700)
 * 
 * lineHeight(28px), lh18, lh20, lh22, lh24, lh26
 * 
 * color(#000), textGrayColor
 */
export const H1 = styled.h1`
    font-size: ${(props) => props.fontSize || '24px'};
    font-size: ${(props) => props.fs25 && '25px'};
    font-size: ${(props) => props.fs26 && '26px'};
    font-size: ${(props) => props.fs27 && '27px'};
    font-size: ${(props) => props.fs28 && '28px'};

    font-weight: ${(props) => props.fontWeight || '700'};

    line-height: ${(props) => props.lh18 && '18px'};
    line-height: ${(props) => props.lh20 && '20px'};
    line-height: ${(props) => props.lh22 && '22px'};
    line-height: ${(props) => props.lh24 && '24px'};
    line-height: ${(props) => props.lh26 && '26px'};
    line-height: ${(props) => props.lineHeight || '28px'};

    color: ${(props) => props.color || '#000'};
    color: ${(props) => props.textHeaderGrayColor && 'var(--text-gray-color)'};
`


/**
 * fontSize(20px), fs21, fs22, fs23
 * 
 * fontWeight(700)
 * 
 * lineHeight(28px), lh18, lh20, lh22, lh24, lh26
 * 
 * color(#000), textGrayColor
 */
export const H2 = styled.h2`
    font-size: ${(props) => props.fontSize || '20px'};
    font-size: ${(props) => props.fs21 && '21px'};
    font-size: ${(props) => props.fs22 && '22px'};
    font-size: ${(props) => props.fs23 && '23px'};

    font-weight: ${(props) => props.fontWeight || '700'};

    line-height: ${(props) => props.lineHeight || '28px'};
    line-height: ${(props) => props.lh18 && '18px'};
    line-height: ${(props) => props.lh20 && '20px'};
    line-height: ${(props) => props.lh22 && '22px'};
    line-height: ${(props) => props.lh24 && '24px'};
    line-height: ${(props) => props.lh26 && '26px'};

    color: ${(props) => props.color || '#000'};
    color: ${(props) => props.textHeaderGrayColor && 'var(--text-gray-color)'};
`


/**
 * fontSize(16px), fs17, fs18, fs19
 * 
 * fontWeight(700)
 * 
 * lineHeight(28px), lh18, lh20, lh22, lh24, lh26
 * 
 * color(#000), textGrayColor
 */
export const H3 = styled.h3`
    color: ${(props) => props.color || '#000'};
    font-size: ${(props) => props.fontSize || '16px'};
    font-weight: ${(props) => props.fontWeight || '700'};
    line-height: ${(props) => props.lineHeight || '28px'};

    font-size: ${(props) => props.fs14 && '14px'};
    font-size: ${(props) => props.fs15 && '15px'};
    font-size: ${(props) => props.fs17 && '17px'};
    font-size: ${(props) => props.fs18 && '18px'};
    font-size: ${(props) => props.fs19 && '19px'};

    line-height: ${(props) => props.lh18 && '18px'};
    line-height: ${(props) => props.lh20 && '20px'};
    line-height: ${(props) => props.lh22 && '22px'};
    line-height: ${(props) => props.lh24 && '24px'};
    line-height: ${(props) => props.lh26 && '26px'};

    color: ${(props) => props.textHeaderGrayColor && 'var(--text-gray-color)'};
`


/**
 * fontSize(14px), fs15
 * 
 * fontWeight(700)
 * 
 * lineHeight(28px), lh18, lh20, lh22, lh24, lh26
 * 
 * color(#000), textGrayColor
 */
export const H4 = styled.h4`
    font-weight: ${(props) => props.fontWeight || '700'};

    font-size: ${(props) => props.fontSize || '14px'};
    font-size: ${(props) => props.fs15 && '15px'};

    line-height: ${(props) => props.lineHeight || '28px'};
    line-height: ${(props) => props.lh14 && '14px'};
    line-height: ${(props) => props.lh16 && '16px'};
    line-height: ${(props) => props.lh18 && '18px'};
    line-height: ${(props) => props.lh20 && '20px'};
    line-height: ${(props) => props.lh22 && '22px'};
    line-height: ${(props) => props.lh24 && '24px'};
    line-height: ${(props) => props.lh26 && '26px'};

    color: ${(props) => props.color || '#000'};
    color: ${(props) => props.textGrayColor && 'var(--text-gray-color)'};
`
