import styled from "styled-components";

const Image = styled.img`
    width: ${(props) => props.width || "100%"};
    height: ${(props) => props.height || ""};
    cursor: ${(props) => props.pointer ? 'pointer' : ''};
    object-fit: cover;
    display: ${(props) => (props.visible === undefined)? '' : ((props.visible)?'block':'none')};
    margin: ${(props) => (props.center === undefined)? '' : 'auto'};
`

export default Image;