import styled, { css } from "styled-components";

// 공통 Input Tag
const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    
    width: ${(props) => props.width || '343px'};
    min-width: 70px;
    height: ${(props) => props.height || '60px'};

    border: ${(props) => props.border || 'none'};
    border-radius: ${(props) => props.borderRadius || '12px'};
    background: #FFF4B5;

    padding: ${(props) => props.padding || '0'};

    line-height: 28px;
    font-size: ${(props) => props.fontSize || '18px'};
    font-weight: ${(props) => props.fontWeight || '400'};
    color: ${(props) => props.color || '#E1D175'};
    word-break:break-all;

    cursor: default;

    &.active {
        background: #FFCD00;
        color: #000000;
        font-weight: 700;
        cursor: pointer;
    }

    &.center {
        margin: 0 auto;
    }
    
    ${(props) =>
        props.cancel &&
        css`
        color: #000000;
        background-color: #F9F9F9;
        cursor: pointer;
    `}

    ${(props) =>
        props.outline &&
        css`
        border: 1px solid #FFCD00;
        color: #000000;
        background-color: #FFF;
        cursor: pointer;
    `}
`
export default Button;