import { useEffect } from "react";

const analyticsDeploymentEnvironment = "release";


const NaverAnalytics = () => {
    
    useEffect(() => {
        if (process.env.REACT_APP_ENV_TYPE === analyticsDeploymentEnvironment) {
            if (!window.wcs_add) window.wcs_add = {};
            window.wcs_add["wa"] = "1912d8f327f6c70";
            if (window.wcs) window.wcs_do();
        }
    }, []);

    return <></>;
}
export default NaverAnalytics; 
