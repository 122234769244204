import styled from "styled-components";
// components
import Img from "components/atoms/Image"
// images
import AlertIcon from "assets/images/common/ic_alert_r.png";
import AlertRedIcon from "assets/images/common/ic_alert_r_red.png";
// commons
// Regex
import { emailRegExWordTest } from 'commons/regex/Email'


const Input = ({
    value,
    error,
    onEnter,
    onClear, 
    ...rest
}) => {
    return (
        <InputContainer>
            <InputStyled
                type="text"
                value={value}
                error={error}
                onKeyDown={(e) => !emailRegExWordTest(e.key) && e.preventDefault()}
                onKeyUp={(e) => onEnter && e.key === "Enter" && onEnter()}

                {...rest}
            />
            {
                value && onClear &&
                <InputErrorContainer onClick={onClear}>
                    <Img src={error ? AlertRedIcon : AlertIcon} pointer alt="Error Icon" />
                </InputErrorContainer>
            }
        </InputContainer>
    )
}
export default Input;


const InputContainer = styled.div`
    position: relative;
    width: 100%;
`

const InputStyled = styled.input`
    width: ${(props) => props.width || '100%'};
    height: 60px;
    padding: 18px 36px 18px 16px;

    border: 0.5px solid #CECECE;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 700;
    -webkit-appearance: none;

    &:focus { outline: none; }
    &::placeholder { font-weight: 400; color:#999999; }
    ${({ value }) => value && `border-color: #FFCD00;`}
    ${({ error }) => error && `border-color: #CE2029;`}
`

const InputErrorContainer = styled.div`
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translate(0,-50%);

    width: 16px;
    height: 16px;
`