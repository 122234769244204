/* eslint-disable */
import { EMAIL_MAX_LENGTH } from 'commons/Settings'

/**
 * 이메일 정규식 테스트
 * @param {string} email 
 * @returns Boolean
 */
export const emailRegExTest = (email) => {
    return /^[0-9a-zA-Z]([-\.]?[0-9a-zA-Z|\_!#$%*/=?^])*@[0-9a-zA-Z]([-\.]?[0-9a-zA-Z])*\.[a-zA-Z]{1,}$/.test(email);

    // RFC 5322 규칙을 만족하는 정규식
    // return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
}
/**
 * 이메일 정규식 Replace 함수
 * @param {string} email
 * @returns regEx Replace Email
 */
export const emailRegExReplace = (email) => {
    return email.replace(/[^a-zA-Z0-9|\_!#$%*/=?^.@-]/g, "").slice(0, EMAIL_MAX_LENGTH)
}

export const emailRegExWordTest = (word) => {
    return /[0-9a-zA-Z-\.|\_!#$%*/=?^@]/.test(word);
}
