import React, { useEffect } from 'react';
// import axios from "axios";
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
// utils
import { retryLazy } from 'utils/lazyUtil.js';
import customAxios from 'axios/customAxios';

// components
// common
import DefaultContainer from 'components/templates/common/defaultContainer';
import ProtectedRoute from 'components/commons/ProtectedRoute';
import { getAccessToken, setAccessToken } from 'commons/Token/tokenStorage';

import { getPathAfterLogin } from 'pages/Login';

const Login = retryLazy(() => import('pages/Login'));
const Renewal = retryLazy(() => import('pages/Renewal'));
const SignUp = retryLazy(() => import('pages/SignUp/router'));
const FindEmail = retryLazy(() => import('pages/FindEmail/router'));
const FindPassword = retryLazy(() => import('pages/FindPassword/router'));

const MyInfo = retryLazy(() => import('pages/MyInfo/router'));
const StudentInfo = retryLazy(() => import('pages/StudentInfo/router'));
const Reservation = retryLazy(() => import('pages/Reservation/router'));
const PaymentHistory = retryLazy(() => import('pages/PaymentHistory/router'));
const ReservationManagement = retryLazy(() =>
    import('pages/ReservationManagement/router')
);

const Notice = retryLazy(() => import('pages/Notice/router'));
const HelpDesk = retryLazy(() => import('pages/HelpDesk'));
const NotFound = retryLazy(() => import('pages/NotFound'));

const Router = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (getAccessToken()) {
            return;
        }
        customAxios({
            method: 'post',
            hostType: 'auth',
            path: '/v2/refresh/cookie',
        }).then(async (data) => {
            if (data.status === 1) {
                setAccessToken(data.data);
                const pathAfterLogin = await getPathAfterLogin();
                navigate(pathAfterLogin);
            }
        });
        // eslint-disable-next-line
    }, []);

    return (
        <DefaultContainer>
            <Routes>
                <Route path="/" element={<Renewal />} />
                {/* <Route path="/" element={<Login />} /> */}
                <Route path="/sign-up/*" element={<SignUp />} />
                <Route path="/find/email/*" element={<FindEmail />} />
                <Route path="/find/pwd/*" element={<FindPassword />} />

                <Route path="/info/my/*" element={<MyInfo />} />
                <Route path="/info/student/*" element={<StudentInfo />} />
                <Route path="/reservation/*" element={<Reservation />} />
                <Route path="/payment-history/*" element={<PaymentHistory />} />
                <Route path="/notice/*" element={<Notice />} />
                <Route
                    path="/help-desk"
                    element={
                        <ProtectedRoute>
                            <HelpDesk />
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/reservation-management/*"
                    element={<ReservationManagement />}
                />

                {/* GA 유입 경로 설정 */}
                <Route
                    path="/qr_carbanner1"
                    element={
                        <GAInflowPath event_name={'QR_현수막_동탄_꿈의길'} />
                    }
                />
                <Route
                    path="/corp1_home"
                    element={
                        <GAInflowPath event_name={'옐로우버스_홈페이지'} />
                    }
                />
                <Route
                    path="/carrot_sc1"
                    element={<GAInflowPath event_name={'당근마켓_서초'} />}
                />
                <Route
                    path="/carrot_dt1"
                    element={<GAInflowPath event_name={'당근마켓_동탄'} />}
                />
                <Route
                    path="/mom_sc1"
                    element={<GAInflowPath event_name={'맘카페_서초'} />}
                />
                <Route
                    path="/mom_dt1"
                    element={<GAInflowPath event_name={'맘카페_동탄'} />}
                />
                <Route
                    path="/sms_open1"
                    element={<GAInflowPath event_name={'문자_0125'} />}
                />

                <Route path="*" element={<NotFound />} />
            </Routes>
        </DefaultContainer>
    );
};
export default Router;

const GAInflowPath = ({ event_name }) => {
    useEffect(() => window.gtag?.('event', event_name), []);
    return <Navigate to="/" replace />;
};
