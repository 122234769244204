import { useQuery } from '@tanstack/react-query'
// commons
// Date
import { convertFormat } from 'commons/Date'
// hooks
import useErrorModal from 'hooks/useErrorModal'
import customAxios from 'axios/customAxios';


const getTodayPaymentHistoryStatusFetcher = () => {
  return customAxios({
    method: 'get',
    path: '/b2c/v2/payments/check',
    auth: true
  }).then(data => data)
}
export const useTodayPaymentHistoryStatusQuery = (params) => {
  const { unexpectedError } = useErrorModal();
  const QUERY_KEY = ['get/todayPaymentHistoryStatus']
  
  return useQuery(
      QUERY_KEY,
      () => getTodayPaymentHistoryStatusFetcher(params),
      {
        staleTime: 1000 * 60 * 60 * 24,
        refetchOnWindowFocus: false,
        onError: () => { unexpectedError() } }
    );
}

const getTicketAvailableStatusFetcher = () => {
  return customAxios({
    method: 'get',
    path: '/b2c/v2/ticket/check',
    auth: true,
    params: {
      startDate: convertFormat(new Date(), "yyyy-MM-dd"),
      endDate: convertFormat(new Date(2100, 1, 1), "yyyy-MM-dd")
    }
  }).then(data => data)
}
export const useTicketAvailableStatusQuery = (params) => {
  const { unexpectedError } = useErrorModal();
  const QUERY_KEY = ['get/ticketAvailableStatus']
  
  return useQuery(
      QUERY_KEY,
      () => getTicketAvailableStatusFetcher(params),
      {
        staleTime: 1000 * 60 * 60 * 24,
        refetchOnWindowFocus: false,
        onError: () => { unexpectedError() } }
    );
}

const getTodayNoticeStatusFetcher = () => {
  return customAxios({
    method: 'get',
    hostType: "auth",
    path: '/v2/notices/check',
    auth: true,
    params: {
      targetType: "SHARE_BUS"
    }
  }).then(data => data)
}
export const useTodayNoticeStatusQuery = (params) => {
  const { unexpectedError } = useErrorModal();
  const QUERY_KEY = ['get/todayNoticeStatus']

  return useQuery(
      QUERY_KEY,
      () => getTodayNoticeStatusFetcher(params),
      {
        staleTime: 1000,
        refetchOnWindowFocus: false,
        onError: () => { unexpectedError() } }
    );
}
