import styled from "styled-components";

// components
// Commons
import Drawer from 'components/commons/Drawer';


const ProtectedContainer = ({ center, padding, height, backgroundColor, children }) => {
    return(
        <>
            <Drawer />

            <MainContainer
                height={height}
                center={center}
                padding={padding}
                backgroundColor={backgroundColor}
            >
                {children}
            </MainContainer>
        </>
    )
}

export default ProtectedContainer;


export const OuterContainer = ({ center, padding, height, backgroundColor, children }) => {
    return (
        <>
            <MainContainer
                height={height}
                center={center}
                padding={padding}
                backgroundColor={backgroundColor}
            >
                {children}
            </MainContainer>
        </>
    )
}

const MainContainer = styled.div`
    display: ${props => props.center && "flex"};
    justify-content: ${props => props.center && "center"};

    max-width: 800px;

    width: 100%;
    height: ${props => props.height || "auto"};

    padding: ${props => props.padding || "24px 16px"};
    background-color: ${props => props.backgroundColor || ""};
`