import { Navigate } from 'react-router-dom';
// commons
import { getAccessToken } from 'commons/Token/tokenStorage'

const ProtectedRoute = ({ children }) => {
    const accessToken = getAccessToken();
    if (!accessToken) { return <Navigate to={"/"} replace /> }
    return children ? children : <></>;
};

export default ProtectedRoute;