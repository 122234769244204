import { Modal } from 'antd';
import styled from "styled-components";


const ModalModule = ({open = false, width, contents, okButton, cancelButton, onClose, onClickOk, children }) => {
    return (
        <StyledModal
            maskStyle={{
                background: "rgba(0, 0, 0, 0.8)"
            }}
            centered
            closable={false}
            footer={null}
            visible={open}
            width={width ?? 343}
        >
            <ContentsContainer>{children}</ContentsContainer>

            <FooterContainer>
                {
                    cancelButton && <CancelButton onClick={() => { onClose() }}>{cancelButton ?? "취소"}</CancelButton>
                }
                <OkButton width={cancelButton && "50%"} onClick={() => { onClickOk() }}>{okButton ?? "확인"}</OkButton>
            </FooterContainer>
        </StyledModal>
    )
}
export default ModalModule;


const StyledModal = styled(Modal)`
    .ant-modal-content{
        border-radius: 4px;
    }

    .ant-modal-body{
        display: flex;
        flex-direction: column;

        padding: 0;
    }
`

const ContentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 54px 16px;

    p, span {
        padding: 4px 0;
        font-size: 18px;
        font-weight: 700;

        /* &.bold{
            font-weight: 700;
        } */
        &.color{
            color: #FF7800;
        }
    }
`

const FooterContainer = styled.div`
    display: flex;
    height: 60px;
    font-size: 16px;
`

const OkButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${(props) => props.width || '100%'};

    border-radius: ${(props) => props.width ? '0 0 4px 0;' : '0 0 4px 4px;'};
    font-weight: 700;
    background-color: #FFCD00;
    cursor: pointer;
`

const CancelButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 50%;

    border-radius: 0 0 0 4px;
    background-color: #F2F2F4;
    cursor: pointer;
`