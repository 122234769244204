import React from 'react'
import styled from 'styled-components'

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
`

const Icon = styled.svg`
  fill: none;
  stroke: ${props => (props.checked ? '#CECECE' : '#FFCD00')};
  stroke-width: 3px;
  stroke-linecap: round;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: ${props => (props.width || '18px')};
  height: ${props => (props.height || '18px')};
  background: ${props => (props.checked ? '#FFF' : '#000')};
  background: ${props => (props.disabled && "none")};
  border: ${props => (props.checked ? '0.5px solid #CECECE' : 'none')};
  border-radius: ${props => (props.square ? '4px' : '10px')};
  transition: all 150ms;

  ${Icon} {
    visibility: ${props => ((props.checked && !props.noCheckView) && 'hidden')};
    /* visibility: ${props => (props.checked ? 'hidden' : 'visible')}; */
  }
`

const Checkbox = ({ className, checked, id, ...props }) => (
  <label>
    <CheckboxContainer className={className} disabled={props.disabled}>
      <HiddenCheckbox checked={!checked} id={id} {...props} />
      <StyledCheckbox checked={!checked} square={props.square} width={props.width} height={props.height} noCheckView={props.noCheckView} disabled={props.disabled}>
        <Icon viewBox="0 0 24 20" checked={!checked} noCheckView={props.noCheckView}>
          <polyline points="7,12 11,16 17,9" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  </label>
)

export default Checkbox;
