import styled from "styled-components";

// commons
import Modal from 'components/commons/Modal';
// store
import { useStore as useErrorModalStore } from 'stores/errorModalStore'



const ErrorModalImport = () => {
    const { 
        data,
        isModal, setIsModal
    } = useErrorModalStore();
    return( 
        <Modal open={isModal} close={() => setIsModal(false)} onClickOk={() => setIsModal(false)}>
            <p className="bold">Error Code: {data.code}</p>
            <p style={{fontSize: "14px"}}>{data.message}</p>
        </Modal>
    )
}

const Default = ({ children }) => {
    return(
        <>
            <ErrorModalImport />
            
            <HTMLContainer>
                <BodyContainer>{children}</BodyContainer>
            </HTMLContainer>
        </>
    )
}

export default Default;


const HTMLContainer = styled.div`
    // background-color: green;
    display: flex;
    flex-direction: column;
    
    width: 100%;
    height: 100%;
`

const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    min-width: 320px;
    height: 100%;
`