// Eamil
export const EMAIL_MIN_LENGTH = 10;
export const EMAIL_MAX_LENGTH = 33;

// Password
export const PASSWORD_MIN_LENGTH = 10;
export const PASSWORD_MAX_LENGTH = 64;

// Name
// 학생
export const STUDENT_NAME_MIN_LENGTH = 2;
export const STUDENT_NAME_MAX_LENGTH = 10;
// 학부모
export const PARENT_NAME_MIN_LENGTH = 2;
export const PARENT_NAME_MAX_LENGTH = 13;

// Phone
export const PHONE_MAX_LENGTH = 13;

// Validation Code
export const VALIDATION_CODE_MAX_LENGTH = 6;