import { useState, useRef, useLayoutEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { SwipeableDrawer } from '@mui/material';
import styled from "styled-components";

// images
import CloseButtonImage from 'assets/images/drawer/btn-cancel@4x.png'

import BusIcon from 'assets/images/drawer/drawer_bus.png'
import SignIcon from 'assets/images/drawer/drawer_sign.png'

import MyInfoIcon from 'assets/images/drawer/icon_myinfo@4x.png' // 내 정보
import MySupvIcon from 'assets/images/drawer/icon_mysupv@4x.png' // 학생 정보
import ReservationIcon from 'assets/images/drawer/icon_reservation@4x.png' // 승차권 예약
import PaymentIcon from 'assets/images/drawer/icon_payment@4x.png' // 예약 관리
import ReservationManagementIcon from 'assets/images/drawer/icon_reserv_manage@4x.png' // 예약 관리
import NoticeIcon from 'assets/images/drawer/icon_notice@4x.png' // 공지 사항
import DocumentsIcon from 'assets/images/drawer/icon_document@4x.png' // 이용 약관, 개인정보 처리방침
import HelpDeskIcon from 'assets/images/drawer/icon-call@4x.png' // 고객 센터

import RightArrow from 'assets/images/drawer/arrow@4x.png' // 오른쪽 화살표
import YellowbusLogo from 'assets/images/drawer/yellowbus_logo@3x.png'

// components
// Commons
import Modal from 'components/commons/Modal';
// atoms
import Span from 'components/atoms/Span';
import Img from 'components/atoms/Image';
import P from 'components/atoms/Paragraph';
// modules
import TermsPolicyBottomSheet from 'components/modules/policy/termsBottomSheet'
import PrivacyPolicyBottomSheet from 'components/modules/policy/privacyBottomSheet'
// store
import { useStore as useDrawerStore } from 'stores/drawerStore'

// queries
import { useMyInfoQuery } from 'queries/MyInfo/useMyInfoQuery'
import { useTodayPaymentHistoryStatusQuery } from 'queries/Drawer/useDrawerQuery'
import { useTicketAvailableStatusQuery } from 'queries/Drawer/useDrawerQuery'
import { useTodayNoticeStatusQuery } from 'queries/Drawer/useDrawerQuery'

import customAxios from "axios/customAxios";
import { useEffect } from 'react';

// commons
import { clearToken } from 'commons/Token/tokenStorage'


const DrawerModule = () => {
    const navigate = useNavigate();
    const termsPolicyRef = useRef();
    const privacyPolicyRef = useRef();

    const { isOpen, closeDrawer } = useDrawerStore();

    const [isLogoutModal, setIsLogoutModal] = useState(false)

    // 내 정보(name, email)
    const { data: myInfoData, refetch } = useMyInfoQuery();
    // 예약 관리
    const { data: ticketAvailableStatusData } = useTicketAvailableStatusQuery();
    // 결제 내역
    const { data: todayPaymentHistoryStatusData } = useTodayPaymentHistoryStatusQuery();
    // 공지사항
    const { data: todayNoticeStatusData } = useTodayNoticeStatusQuery();

    useLayoutEffect(() => {
        refetch();
    }, [])

    useEffect(() => {
        if(isOpen)
            window.gtag?.("event", "네비게이션_메뉴");
    }, [isOpen])

    const movePage = (url) => {
        closeDrawer();
        navigate(url);
    }

    const onClickLogout = () => {

        customAxios({
            method: 'delete',
            hostType: "auth",
            path: '/v2/refresh/cookie'
        }).finally(() => {
            closeDrawer();
            clearToken()
            navigate('/');
        })
    }

    return (
        <>
            <TermsPolicyBottomSheet
                ref={termsPolicyRef}
            />
            <PrivacyPolicyBottomSheet
                ref={privacyPolicyRef}
            />

            <Modal open={isLogoutModal} onClose={() => setIsLogoutModal(false)} okButton={"로그아웃"} cancelButton={"취소"} onClickOk={onClickLogout}>
                <p>로그아웃 하시겠습니까?</p>
            </Modal>

            <SwipeableDrawer
                open={isOpen}
                onOpen={() => { }}
                onClose={closeDrawer}
                disableSwipeToOpen={true}

                BackdropProps={{
                    style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' }
                }}

                PaperProps={{
                    sx: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none'
                    }
                }}
            >
                <DrawerContainer>
                    <DrawerWrap>
                        <div>
                            <HeaderContainer>
                                <P bold fontSize={"18px"}>{myInfoData?.data.name ?? ""}<Span fontSize={"12px"}>님 반갑습니다!</Span></P>
                                <Span bold>{myInfoData?.data.email ?? ""}</Span>

                                <LogoutButton onClick={() => setIsLogoutModal(true)}>로그아웃</LogoutButton>

                                <ImageWrap>
                                    <Img src={BusIcon} width={54} alt="Drawer Bus Icon" />
                                    <Img src={SignIcon} width={22} alt="Drawer Bus-Sign Icon" />
                                </ImageWrap>
                            </HeaderContainer>

                            <BodyContainer>
                                <TopNavWrap>
                                    <NavMenu
                                        text="내 정보"
                                        imgSrc={MyInfoIcon}
                                        onClick={() => { movePage("/info/my") }}
                                    />
                                    <NavMenu
                                        text="학생 정보"
                                        imgSrc={MySupvIcon}
                                        onClick={() => { movePage("/info/student") }}
                                    />
                                    <NavMenu
                                        text="승차권 예약"
                                        imgSrc={ReservationIcon}
                                        onClick={() => { movePage("/reservation/type") }}
                                    />
                                    <NavMenu
                                        text="예약 관리"
                                        imgSrc={ReservationManagementIcon}
                                        isAlarm={ticketAvailableStatusData?.data}
                                        onClick={() => { movePage("/reservation-management") }}
                                    />
                                    <NavMenu
                                        text="결제 내역"
                                        imgSrc={PaymentIcon}
                                        isAlarm={todayPaymentHistoryStatusData?.data}
                                        onClick={() => { movePage("/payment-history/list") }}
                                    />
                                    <NavMenu
                                        text="공지사항"
                                        imgSrc={NoticeIcon}
                                        isAlarm={todayNoticeStatusData?.data}
                                        onClick={() => { movePage("/notice") }}
                                    />
                                    <NavMenu
                                        text="고객센터"
                                        imgSrc={HelpDeskIcon}
                                        onClick={() => { movePage("/help-desk") }}
                                    />
                                </TopNavWrap>

                                <BottomNavWrap>
                                    <NavMenu
                                        id="drawer_termsPolicy"
                                        text="이용 약관"
                                        imgSrc={DocumentsIcon}
                                        onClick={() => { 
                                            window.gtag?.("event", "이용약관_메뉴");
                                            termsPolicyRef.current.setOpen();
                                        }}
                                    />
                                    <NavMenu
                                        id="drawer_privacyPolicy"
                                        text="개인정보 처리방침"
                                        imgSrc={DocumentsIcon}
                                        onClick={() => { 
                                            window.gtag?.("event", "개인정보처리방침_메뉴");
                                            privacyPolicyRef.current.setOpen();
                                        }}
                                    />
                                </BottomNavWrap>

                            </BodyContainer>

                        </div>
                        <FooterContainer />

                    </DrawerWrap>

                    <CloseButtonArea onClick={closeDrawer}>
                        <Img src={CloseButtonImage} pointer width={"48px"} alt={"Drawer 닫기 버튼"} />
                    </CloseButtonArea>

                </DrawerContainer>
            </SwipeableDrawer>
        </>
    )
}
export default DrawerModule;


const NavMenu = ({ onClick, imgSrc, text, isAlarm, id }) => {
    return (
        <MenuBox 
            onClick={() => onClick()}
            id={id}
        >
            <MuneContent>
                <Img src={imgSrc} width={"24px"} alt={text} />
                {text}
            </MuneContent>
            { isAlarm && <AlarmCircle /> }
        </MenuBox>
    )
}


const DrawerContainer = styled.div`
    /* width: 400px; */
    /* border: 1px solid red; */
    display: flex;
    height: 100%;
    overflow: hidden;
`
const CloseButtonArea = styled.div`
    width: 48px;
    height: 100%;
`
const DrawerWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    /* width: 100%; */
    width: 312px;
    height: 100%;
    /* max-width: 312px; */
    @media screen and (max-width: 400px) {
        width: 78vw;
        min-width: 248px;
    }

    background-color: #FFF;
    overflow-y: auto;
`

const HeaderContainer = styled.div`
    /* border: 1px solid red; */
    position: relative;
    display: flex;
    flex-direction: column;

    width: 100%;
    /* height: 145px; */
    min-height: 145px;
    height: auto;
    padding: 28px 24px 16px 24px;

    background-color: #FFF4B5;
    border-bottom: 12px solid #FFED99;

    > span {
        flex-shrink: 0;

        margin-top: 4px;
        margin-bottom: 8px;
        
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`

const LogoutButton = styled.button`
    flex-shrink: 0;

    width: 74px;
    height: 32px;
    
    color: #FFF;
    font-size: 14px;
    font-weight: 700;
    border: none;
    border-radius: 4px;
    background-color: #000;
`
const ImageWrap = styled.div`
    position: absolute;
    bottom: -7px;
    right: 16px;
`

const BodyContainer = styled.div`
    /* flex-shrink: 0; */
    
    width: 100%;
    height: auto;
    padding: 14px 24px;
`
const TopNavWrap = styled.div`
    padding-bottom: 6px;
    border-bottom: 1px solid #F4F4F4;
    /* @media screen and (max-height: 720px) {
        padding-bottom: 2vh;
    } */
`
const BottomNavWrap = styled.div`
    padding-top: 6px;
    /* @media screen and (max-height: 720px) {
        padding-top: 1vh;
    } */
`
const FooterContainer = styled.div`
    display: flex;

    width: 100%;
    height: 72px;
    min-height: 50px;
    @media screen and (max-height: 500px) {
        height: 15vh;
    }

    background-image: url(${YellowbusLogo});
    background-size: 150px 24px;
    background-repeat: no-repeat;
    background-position: left 24px top 50%;
`

const MenuBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: auto;
    /* padding: 20px 6px 20px 3px; */
    padding: 14px 28px 14px 0;
    @media screen and (max-height: 700px) {
        padding: 2vh 28px 2vh 0;
    }
    
    cursor: pointer;
    
    // 오른쪽 화살표
    background-image: url(${RightArrow});
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: right center;
`

const MuneContent = styled.div`
    display: flex;
    align-items: center;

    width: 100%;
    height: auto;

    font-size: 16px;
    cursor: pointer;

    > img {
        margin-right: 12px;
    }
`

const AlarmCircle = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #CE2029;
    /* margin-right: 28px; */
`

// const DrawerCloseDiv = styled.div`
//     position: absolute;
//     top: 15px;
//     right: -32px;
//     font-size: 16px;
//     color: #FFFFFF;
//     cursor: pointer;
// `
