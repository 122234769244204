import React from 'react'
import { css } from "@emotion/react";
import { ImpulseSpinner } from "react-spinners-kit";
import styled from "styled-components";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: rgba(255,255,255,0.7);
  border-width: 10px;
`;

const Loading = (props) => {
    const check = props.loading == null ? false : props.loading

    return (
        check &&
        <LoadingContainer style={{display:props.loading===true?'block':'none'}}>
            <LoadingIndicator>
                <ImpulseSpinner frontColor={"#FFCD00"} loading={props.loading} css={override}  size={60} />
            </LoadingIndicator>
        </LoadingContainer>
    )

}

export default Loading


const LoadingContainer = styled.div`
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    overflow: auto;
    display: block;
    position: fixed !important;
    background-color: rgba(0, 0, 0, 0.6);
`

const LoadingIndicator = styled.div`
    top: 45%;
    left: calc(50% - 30px);
    position: fixed;
`